<script setup lang="ts"></script>

<template>
  <RouterView />
</template>

<style>
html,
body,
#app {
  color: #252f44;
  height: auto !important;
  background-color: #fff;
}

.button {
  display: inline-block;
  width: 140px;
  height: 44px;
  background: #2569e0;
  border-radius: 4px;
  line-height: 44px;
  color: white;
  font-size: 16px;
  text-align: center;
  transition: all 0.5s ease-out;
}
.button:hover {
  opacity: 0.8;
}
.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}
.line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.group-tabs {
  display: flex;
  font-size: 16px;
  color: #2569e0;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
}
.group-tabs .group-tab-item {
  padding: 11px 28px;
  background-color: #e8f0ff;
  cursor: pointer;
}
.group-tabs .group-tab-item.group-tab-item-active {
  color: white;
  background-color: #2569e0;
}
.animated {
  transition: all 0.5s ease-out;
}
</style>
