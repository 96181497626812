const home = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/home/index.vue'),
    meta: {
      title: '首页',
      keepAlive: true,
      deepth: 1,
    },
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('@/pages/list/index.vue'),
    meta: {
      title: '列表',
      keepAlive: true,
      deepth: 2,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/pages/about/index.vue'),
    meta: {
      title: '联系我们',
    },
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/pages/detail/index.vue'),
    meta: {
      title: '新闻详情',
    },
  },
];

export default home;
